// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-blog-index-js": () => import("./../../../src/components/templates/blog-index.js" /* webpackChunkName: "component---src-components-templates-blog-index-js" */),
  "component---src-components-templates-blog-post-js": () => import("./../../../src/components/templates/blog-post.js" /* webpackChunkName: "component---src-components-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-collections-stable-diffusion-index-mdx": () => import("./../../../src/pages/collections/stable-diffusion/index.mdx" /* webpackChunkName: "component---src-pages-collections-stable-diffusion-index-mdx" */),
  "component---src-pages-collections-swiftui-2022-index-mdx": () => import("./../../../src/pages/collections/swiftui-2022/index.mdx" /* webpackChunkName: "component---src-pages-collections-swiftui-2022-index-mdx" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thank-you-js": () => import("./../../../src/pages/contact/thank-you.js" /* webpackChunkName: "component---src-pages-contact-thank-you-js" */),
  "component---src-pages-frequent-typos-index-mdx": () => import("./../../../src/pages/frequent-typos/index.mdx" /* webpackChunkName: "component---src-pages-frequent-typos-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-itunes-index-js": () => import("./../../../src/pages/itunes/index.js" /* webpackChunkName: "component---src-pages-itunes-index-js" */),
  "component---src-pages-nope-index-js": () => import("./../../../src/pages/nope/index.js" /* webpackChunkName: "component---src-pages-nope-index-js" */),
  "component---src-pages-pip-my-safari-index-js": () => import("./../../../src/pages/pip-my-safari/index.js" /* webpackChunkName: "component---src-pages-pip-my-safari-index-js" */),
  "component---src-pages-portfolio-index-mdx": () => import("./../../../src/pages/portfolio/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-index-mdx" */),
  "component---src-pages-resume-developer-mdx": () => import("./../../../src/pages/resume/developer.mdx" /* webpackChunkName: "component---src-pages-resume-developer-mdx" */),
  "component---src-pages-syndicate-index-js": () => import("./../../../src/pages/syndicate/index.js" /* webpackChunkName: "component---src-pages-syndicate-index-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-writing-chrome-no-more-index-mdx": () => import("./../../../src/pages/writing/chrome-no-more/index.mdx" /* webpackChunkName: "component---src-pages-writing-chrome-no-more-index-mdx" */)
}

